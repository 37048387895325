import React from "react";
import { Box, Image } from "@cuneilego/web";
import webImage from "../../images/web.png";
import { useImageScrollAnimation } from "../../hoocks/useImageScrollAnimation";

export const WebImage: React.FC = () => {
  const containerId = "web-image-container";
  const className = "web-design";
  const { containerRef } = useImageScrollAnimation(className, containerId);
  return (
    <Box
      id={containerId}
      ref={containerRef}
      maxWidth={930}
      maxHeight={798}
      width="100%"
      style={{ aspectRatio: "1/0.85" }}
      overflow="hidden"
      borderRadius={16}
      position="relative"
      bg="#FEF2F2"
    >
      <Image
        position="absolute"
        className={className}
        top={0}
        left={0}
        maxWidth={1249}
        width="134%"
        maxHeight={949}
        style={{ objectFit: "cover", aspectRatio: "1/0.85" }}
        src={webImage}
      />
    </Box>
  );
};
