import React, { useState } from "react";
import { Body1, Input } from "../../ui";
import { Box, Button, Flex, Image } from "@cuneilego/web";
import loadingIcon from "./loading.svg";
import axios from "axios";

export const Form: React.FC = () => {
  const btnProps: any = {
    size: {
      xl: 72,
      lg: 72,
      md: 64,
      sm: 56,
      xs: 48,
      _: 48,
    },
  };
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [dirty, setDirty] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  });

  function onChange(e: any) {
    const value = e.target.value;
    const target = e.target.name;
    setValues((prevState) => ({ ...prevState, [target]: value }));
    checkInputs(e);
  }

  function checkInputs(e: any) {
    const value = e.target.value;

    const checkEmail = () => {
      const isValidEmail = value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      if (value === "") {
        setErrors((prevState) => ({
          ...prevState,
          email: "Please Enter Your Email Address",
        }));
      } else if (!!value && isValidEmail) {
        setErrors((prevState) => ({ ...prevState, email: "" }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          email: "Invalid Email Address",
        }));
      }
    };
    const target = e.target.name;
    switch (target) {
      case "name":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            name: "Please Enter Your Name",
          }));
        } else if (value.length > 0 && value.length < 3) {
          setErrors((prevState) => ({
            ...prevState,
            name: "Name must be at least 3 characters",
          }));
        } else {
          setErrors((prevState) => ({ ...prevState, name: "" }));
        }
        break;
      case "email":
        checkEmail();
        break;
      case "message":
        if (value.length === 0) {
          setErrors((prevState) => ({
            ...prevState,
            message: "Please Enter Your Message",
          }));
        } else if (value.length > 0 && value.length < 25) {
          setErrors((prevState) => ({
            ...prevState,
            message: "Message must be at least 25 characters",
          }));
        } else {
          setErrors((prevState) => ({ ...prevState, message: "" }));
        }
        break;
    }
  }

  function onBlur(e: any) {
    const target = e.target.name;
    switch (target) {
      case "name":
        setDirty((prevState) => ({ ...prevState, name: true }));
        break;
      case "email":
        setDirty((prevState) => ({ ...prevState, email: true }));
        break;
      case "message":
        setDirty((prevState) => ({ ...prevState, message: true }));
        break;
    }
    checkInputs(e);
  }

  async function onSubmit() {
    if (isValid()) {
      setIsLoading(true);
      try {
        axios({
          method: "post",
          url: "https://backendapplication.azure-api.net/echo",
          data: {
            name: values.name,
            email: values.email,
            phoneNumber: values.phone,
            msg: values.message,
          },
        });
      } catch (e) {
        console.error(e);
      }
      setValues({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
      setIsLoading(false);
    }
  }

  function isValid(): boolean {
    return (
      !!values.name &&
      !!values.email &&
      !!values.message &&
      !errors.email &&
      !errors.name &&
      !errors.message
    );
  }

  return (
    <Flex flexDirection="column">
      <Input
        name="name"
        flex={{
          xl: 0.7,
          lg: 0.7,
          md: 0.7,
          sm: 1,
          xs: 1,
        }}
        placeholder="Name"
        value={values.name}
        onChange={onChange}
        dirty={dirty.name}
        message={errors.name}
        onBlur={onBlur}
      />
      <Flex
        my={24}
        flexDirection={{
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column",
          xs: "column",
        }}
      >
        <Box
          flex={1}
          mr={{
            xl: 8,
            lg: 8,
            md: 8,
            sm: 0,
            xs: 0,
          }}
          mb={{
            xl: 0,
            lg: 0,
            md: 0,
            sm: 24,
            xs: 24,
          }}
        >
          <Input
            name="email"
            placeholder="Email"
            value={values.email}
            onChange={onChange}
            onBlur={onBlur}
            dirty={dirty.email}
            message={errors.email}
          />
        </Box>
        <Box
          flex={1}
          height={64}
          ml={{
            xl: 8,
            lg: 8,
            md: 8,
            sm: 0,
            xs: 0,
          }}
        >
          <Input
            name="phone"
            placeholder="Phone Number (Optional)"
            value={values.phone}
            onChange={onChange}
          />
        </Box>
      </Flex>
      <div>
        <textarea
          name="message"
          style={{
            fontFamily: "Manrope",
            fontSize: 16,
            background: "transparent",
            borderColor: "#4F5A68",
            height: 304,
            borderRadius: 8,
            boxSizing: "border-box",
            appearance: "none",
            borderWidth: 2,
            padding: 16,
            color: "#fff",
            width: "100%",
          }}
          placeholder="Message"
          value={values.message}
          onChange={onChange}
          onBlur={onBlur}
        />
        <Flex mt={8} alignItems="center">
          {dirty.message && errors.message && (
            <Body1 color="#F08686">{errors.message}</Body1>
          )}
          <Body1 color="text6" ml="auto">
            {`${values.message.length}`}
          </Body1>
        </Flex>
      </div>
      <Box ml="auto" position="relative" mt={48} overflow="hidden" height={72}>
        {isLoading && (
          <Image
            src={loadingIcon}
            size={32}
            position="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            m="auto"
          />
        )}
        <Button
          onClick={onSubmit}
          isRounded={true}
          disabled={isValid()}
          width={200}
          label={isLoading ? "" : "SEND"}
          {...btnProps}
          style={{
            background: isValid() ? "#5747D1" : "rgba(255, 255, 255, 0.06)",
            padding: 0,
            margin: 0,
            fontWeight: 700,
            color: isValid() ? "#fff" : "rgba(255, 255, 255, 0.24)",
            cursor: isValid() ? "pointer" : "not-allowed",
          }}
        />
      </Box>
    </Flex>
  );
};
