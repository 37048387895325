import React from "react";
import { Box, Image } from "@cuneilego/web";
import consultantImage from "../../images/consultant.png";
import { useImageScrollAnimation } from "../../hoocks/useImageScrollAnimation";

export const ConsultantImage: React.FC = () => {
  const containerId = "consultant-image-container";
  const className = "consultant-design";
  const { containerRef } = useImageScrollAnimation(className, containerId);
  return (
    <Box
      id={containerId}
      ref={containerRef}
      maxWidth={930}
      maxHeight={798}
      width="100%"
      style={{ aspectRatio: "1/0.85" }}
      overflow="hidden"
      borderRadius={16}
      position="relative"
      bg="#F3E5DE"
    >
      <Image
        position="absolute"
        className={className}
        top={0}
        left={0}
        maxWidth={1164}
        width="125%"
        maxHeight={954}
        style={{ objectFit: "cover", aspectRatio: "1/0.85" }}
        src={consultantImage}
      />
    </Box>
  );
};
