import React from "react";
import { Body2, Container, Display1 } from "../../ui";
import { Box, Flex, Image } from "@cuneilego/web";
import teamSvg from "./team.svg";
import { useScrollAnimation } from "../../hoocks/useScrollAnimation";

export const TeamSection: React.FC = () => {
  const containerId = "team-container-id";
  const className = "team-text";
  useScrollAnimation(className, containerId);

  const imageContainerId = "team-image-container-id";
  const imageClassName = "team-image-text";
  useScrollAnimation(imageClassName, imageContainerId, "opacity");
  return (
    <Container
      py={{
        xl: 352,
        lg: 304,
        md: 240,
        _: 120,
      }}
    >
      <Flex
        flexDirection={{
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column-reverse",
          xs: "column-reverse",
          _: "column-reverse",
        }}
      >
        <Box
          id={containerId}
          flex={1}
          mb={{
            _: 178,
            xs: 40,
          }}
        >
          <Box overflow="hidden" mb="md">
            <Display1 className={className}>Our Team</Display1>
          </Box>
          <Box overflow="hidden">
            <Body2 className={className}>
              We are a people-driven business. We value our users and team
              members; we consider them valuable aspects of our success. We are
              quick learners, adaptive to change, and eager to handle daily
              challenges.
            </Body2>
          </Box>
          <Box overflow="hidden">
            <Body2 className={className}>
              We work to foster an environment that is both open and
              supportive—one where we can grow together, learn from each other,
              and share in the success of our achievements.
            </Body2>
          </Box>
        </Box>
        <Box
          id={imageContainerId}
          flex={1.4}
          overflow="hidden"
          mt={{
            xl: "14%",
            lg: "14%",
            md: "14%",
            sm: "14%",
            xs: 80,
          }}
        >
          <Image
            className={imageClassName}
            mb={{
              xl: 0,
              lg: 0,
              md: 0,
              sm: 80,
              xs: 80,
            }}
            src={teamSvg}
            size="100%"
            display={{
              xl: "inline-block",
              lg: "inline-block",
              md: "inline-block",
              sm: "block",
              xs: "block",
              _: "block",
            }}
            pl={{
              xl: 64,
              lg: 64,
              md: 64,
              sm: 0,
              xs: 0,
              _: 0,
            }}
          />
        </Box>
      </Flex>
    </Container>
  );
};
