import React from "react";
import { Box, Flex, Image, Text } from "@cuneilego/web";
import { Container } from "../../ui";
import logo from "../header/logo.svg";
import { useWindowDimensions } from "../../hoocks/useWindowDimensions";

export const HeroSection: React.FC = () => {
  const windowDimensions = useWindowDimensions();
  return (
    <Container
      height={{
        xl: windowDimensions.height - 140,
        lg: windowDimensions.height - 80,
        md: windowDimensions.height - 64,
        sm: windowDimensions.height - 64,
        xs: windowDimensions.height - 64,
        _: windowDimensions.height - 64,
      }}
      minHeight={{
        xl: 934,
        lg: 753,
        md: 671,
        sm: 554,
        xs: 580,
        _: 580,
      }}
      py={{
        xl: 152,
        lg: 120,
        md: 104,
        sm: 88,
        xs: 48,
      }}
    >
      <Box
        position="absolute"
        top={{ xl: 80, lg: 80, md: 40, sm: 40, xs: 24, _: 24 }}
        left={{ xl: 80, lg: 80, md: 40, sm: 40, xs: 24, _: 24 }}
      >
        <Image src={logo} height={40} />
      </Box>
      <Flex
        flex={1}
        width="100%"
        height="100%"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
      >
        <Text
          className="gradient-hero-text"
          fontSize={18}
          lineHeight="32px"
          fontWeight={800}
        >
          Simplicity conceals complexity
        </Text>
        <Text
          width={{
            _: "66%",
            xl: "66%",
            lg: "91%",
            md: "79%",
            sm: "91%",
            xs: "100%",
          }}
          className="hero-responsive-title"
          fontWeight={500}
          color="text1"
          mt="sm"
          letterSpacing={0}
        >
          Solid experts in software development and design, we build custom
          websites and mobile applications that simplify your business&apos;
          complexities
        </Text>
      </Flex>
    </Container>
  );
};
