import { useEffect, useState } from "react";

export const useScrollYPosition = () => {
  const [scrollYPosition, setScrollYPosition] = useState(0);
  const threshold = 100;
  const [scrollDir, setScrollDir] = useState<"up" | "down">("up");

  useEffect(() => {
    let previousScrollYPosition = window.scrollY;
    const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
      Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;
    const isScrollingUp = (currentScrollYPosition: number) =>
      currentScrollYPosition > previousScrollYPosition &&
      !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
      !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

    const updateScrollDirection = () => {
      const currentScrollYPosition = window.scrollY;

      if (scrolledMoreThanThreshold(currentScrollYPosition)) {
        const newScrollDirection = isScrollingUp(currentScrollYPosition)
                                   ? "down"
                                   : "up";
        setScrollDir(newScrollDirection);
        previousScrollYPosition =
          currentScrollYPosition > 0 ? currentScrollYPosition : 0;
      }
    };
    const updatePosition = () => {
      requestAnimationFrame(updateScrollDirection);
      setScrollYPosition(scrollY);
    };
    addEventListener("scroll", updatePosition);
    updatePosition();
    return () => removeEventListener("scroll", updatePosition);
  }, []);

  return {
    scrollYPosition,
    scrollDir,
  };
};
