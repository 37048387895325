import { useEffect, useRef } from "react";
import anime from "animejs";
import { useScrollYPosition } from "./scrollYPosition";
import { useWindowDimensions } from "./useWindowDimensions";

export function useImageScrollAnimation(
  className: string,
  containerId: string
) {
  const ref: any = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { scrollYPosition } = useScrollYPosition();
  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    ref.current = anime({
      targets: `.${className}`,
      translateY: ["-20%", "20%"],
      autoplay: false,
      easing: "easeInOutCubic",
      elasticity: 200,
      delay: (_, i) => (i === 1 ? 100 : 0),
    });
  }, []);

  useEffect(() => {
    const container = document.getElementById(containerId);
    if (containerRef.current && container) {
      const { y, height } = containerRef.current.getBoundingClientRect();
      if (
        scrollYPosition + innerHeight > container.offsetTop &&
        y + height > 0
      ) {
        const scrollPercent = () => {
          return (
            ((scrollYPosition + innerHeight - container.offsetTop) /
              (innerHeight + height)) *
            100
          );
        };
        ref.current.seek((scrollPercent() / 100) * ref.current.duration);
      }
    }
  }, [scrollYPosition, windowDimensions.width]);

  return { containerRef };
}
