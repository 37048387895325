import React from "react";
import { Body1, Container, Title2, Title3 } from "../../ui";
import { Box, Flex, Image } from "@cuneilego/web";
import adaptabilityIcon from "./adaptability.svg";
import reliabilityIcon from "./reliability.svg";
import efficiencyIcon from "./efficiency.svg";
import { Header } from "./header";
import { useScrollAnimation } from "../../hoocks/useScrollAnimation";

interface GoalItemProps {
  title: "Adaptability" | "Reliability" | "Efficiency";
  description: string;
  className: string;
}

const GoalItem = ({ title, description, className }: GoalItemProps) => {
  function renderIcon() {
    switch (title) {
      case "Adaptability":
        return <Image src={adaptabilityIcon} size={80} />;
      case "Reliability":
        return <Image src={reliabilityIcon} size={80} />;
      case "Efficiency":
        return <Image src={efficiencyIcon} size={80} />;
    }
  }
  return (
    <Box
      className={className}
      width={{
        sm: "33%",
        xs: "100%",
      }}
      pr={{
        xl: "9%",
        md: "32px",
        sm: "24px",
        xs: 0,
      }}
      mb={{
        xl: 0,
        lg: 0,
        md: 0,
        sm: 56,
        xs: 56,
        _: 56,
      }}
    >
      {renderIcon()}
      <Title2
        mt={{
          xl: 48,
          lg: 48,
          md: 48,
          sm: 32,
          xs: 16,
          _: 16,
        }}
        mb={{
          xl: 16,
          lg: 16,
          md: 16,
          sm: 16,
          xs: 8,
          _: 8,
        }}
      >
        {title}
      </Title2>
      <Body1>{description}</Body1>
    </Box>
  );
};

export const GoalSection: React.FC = () => {
  const containerId = "goals-driven-container";
  const className = "goals-driven";
  useScrollAnimation(className, containerId);
  return (
    <Container
      pb={{
        xl: 352,
        lg: 304,
        md: 240,
        _: 120,
      }}
    >
      <Header />
      <Box id={containerId} overflow="hidden">
        <Box overflow="hidden">
          <Title3
            display="block"
            width="100%"
            className={`responsive-title-2 ${className}`}
            fontWeight={600}
            mt={{
              xl: 200,
              lg: 160,
              sm: 120,
              _: 80,
            }}
          >
            Our Goal Driven By
          </Title3>
        </Box>
        <Flex
          mt={{
            xl: 80,
            lg: 56,
            md: 56,
            _: 48,
          }}
          width="100%"
          flexDirection={{
            xl: "row",
            lg: "row",
            md: "row",
            sm: "row",
            xs: "column",
          }}
        >
          <GoalItem
            title="Adaptability"
            description="We keep up with the latest technology, providing you with up-to-date software with maximum capabilities."
            className={className}
          />
          <GoalItem
            title="Reliability"
            description="We design software with the end-user in mind. Forget tech complications. We provide reliable apps."
            className={className}
          />
          <GoalItem
            title="Efficiency"
            description="Our primary focus is on efficiency by giving you better control over your business, saving you time and money."
            className={className}
          />
        </Flex>
      </Box>
    </Container>
  );
};
