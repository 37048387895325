import React from "react";
import { Box, Flex, Link } from "@cuneilego/web";
import { Body2, Container, Display1 } from "../../ui";
import { FacebookIcon, GithubIcon, LinkedInIcon, TwitterIcon } from "./icons";
import { Form } from "./form";

export const ContactSection: React.FC = () => (
  <Box bg="#14171B">
    <Container
      py={{
        xl: 176,
        lg: 152,
        _: 120,
      }}
    >
      <Display1
        color="#fff"
        mb={{
          xl: 200,
          lg: 160,
          md: 120,
          _: 80,
        }}
      >
        Contact Us
      </Display1>
      <Form />
      <Flex
        alignItems="center"
        justifyContent="center"
        mt={{
          xl: 200,
          lg: 160,
          md: 120,
          _: 80,
        }}
      >
        <Link
          className="cuneiCode-email"
          href="mailto:info@cuneicode.com"
          target="_blank"
        >
          <Body2 color="#fff">info@cuneicode.com</Body2>
        </Link>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          ml="auto"
          width={{
            xl: 240,
            lg: 240,
            md: 240,
            sm: 152,
            xs: 152,
          }}
        >
          <Link href="https://github.com/CuneiCode">
            <GithubIcon size={24} />
          </Link>
          <Link href="https://www.linkedin.com/company/cuneicode">
            <LinkedInIcon size={24} />
          </Link>
          <Link href="https://twitter.com/cuneicode">
            <TwitterIcon size={24} />
          </Link>
          <Link href="https://www.facebook.com/cuneicode/">
            <FacebookIcon size={24} />
          </Link>
        </Flex>
      </Flex>
    </Container>
  </Box>
);
