import React, { ReactElement } from "react";
import {
  Box,
  BoxProps,
  Text,
  TextProps,
  Input as InputComponent,
  InputProps,
} from "@cuneilego/web";

interface ContainerProps extends BoxProps {
  children: ReactElement | ReactElement[];
}

interface CommonTextProps extends TextProps {
  children: string;
}

export const Container: React.FC<ContainerProps> = ({ children, ...props }) => (
  <Box
    maxWidth={1440}
    minWidth={300}
    px={{
      xl: 80,
      lg: 80,
      md: 40,
      sm: 40,
      xs: 24,
      _: 24,
    }}
    mx="auto"
    overflow="hidden"
    {...props}
  >
    {children}
  </Box>
);

export const Display1 = ({
  children,
  className,
  ...props
}: CommonTextProps) => (
  <Text
    className={`responsive-display1-title ${className}`}
    fontWeight={600}
    letterSpacing="0"
    {...props}
  >
    {children}
  </Text>
);

export const Title1 = ({ children, ...props }: CommonTextProps) => (
  <Text
    className="responsive-title-1"
    fontWeight={600}
    letterSpacing={0}
    {...props}
  >
    {children}
  </Text>
);

export const Title2 = ({ children, ...props }: CommonTextProps) => (
  <Text
    fontSize={24}
    fontWeight={700}
    letterSpacing={0}
    lineHeight="150%"
    {...props}
  >
    {children}
  </Text>
);

export const Title3 = ({ children, ...props }: CommonTextProps) => (
  <Text
    className="responsive-title-3"
    fontWeight={600}
    letterSpacing={0}
    {...props}
  >
    {children}
  </Text>
);

export const Body1 = ({ children, ...props }: CommonTextProps) => (
  <Text
    className="responsive-body-1"
    fontWeight={400}
    letterSpacing={0}
    {...props}
  >
    {children}
  </Text>
);
export const Body2 = ({ children, className, ...props }: CommonTextProps) => (
  <Text
    className={`responsive-body-2 ${className}`}
    fontWeight={400}
    letterSpacing={0}
    {...props}
  >
    {children}
  </Text>
);

export const Input = ({
  dirty,
  message,
  ...props
}: InputProps & {
  dirty?: boolean;
  message?: string;
}) => (
  <Box>
    <InputComponent
      style={{
        borderColor: "#4F5A68",
        height: 64,
        color: "#fff",
      }}
      width="100%"
      height="100%"
      borderColor="red"
      placeholder="Name"
      {...props}
    />
    {dirty && message && (
      <Body1 color="#F08686" mt={8}>
        {message}
      </Body1>
    )}
  </Box>
);
