import React from "react";
import { Box, Image } from "@cuneilego/web";
import backendImage from "../../images/backend.png";
import { useImageScrollAnimation } from "../../hoocks/useImageScrollAnimation";

export const BackendImage: React.FC = () => {
  const containerId = "backend-image-container";
  const className = "backend-design";
  const { containerRef } = useImageScrollAnimation(className, containerId);
  return (
    <Box
      id={containerId}
      ref={containerRef}
      maxWidth={930}
      maxHeight={798}
      width="100%"
      style={{ aspectRatio: "1/0.85" }}
      overflow="hidden"
      borderRadius={16}
      position="relative"
      bg="#F4EEFF"
    >
      <Image
        position="absolute"
        className={className}
        top={0}
        left={0}
        width="100%"
        maxWidth={930}
        maxHeight={798}
        style={{ objectFit: "cover", aspectRatio: "1/0.85" }}
        src={backendImage}
      />
    </Box>
  );
};
