import React, { ReactElement } from "react";
import { Box, Flex, Image, Text } from "@cuneilego/web";
import { Body2, Container, Display1, Title1 } from "../../ui";
import backendImage from "../../images/backend.png";
import consultantImage from "../../images/consultant.png";
import { DesignImage } from "./designImage";
import { WebImage } from "./webImage";
import { MobilImage } from "./mobilImage";
import { BackendImage } from "./backendImage";
import { ConsultantImage } from "./consultantImage";

interface Props {
  side: "left" | "right";
  title: string;
  description: string;
  children: ReactElement;
}

const ServiceItem = ({ side, title, description, children }: Props) => {
  const commonImagePadding = {
    xl: 40,
    lg: 32,
    md: 24,
    sm: 24,
    xs: 0,
  };
  return (
    <Flex
      className="row"
      flexDirection={{
        xl: side === "left" ? "row" : "row-reverse",
        lg: side === "left" ? "row" : "row-reverse",
        md: side === "left" ? "row" : "row-reverse",
        sm: side === "left" ? "row" : "row-reverse",
        _: "column",
      }}
      mb={{
        xl: 352,
        lg: 304,
        md: 240,
        sm: 120,
        xs: 80,
        _: 80,
      }}
    >
      <Box
        flex={1}
        mt={{
          xl: 80,
          lg: 48,
          _: 0,
        }}
        mb={{
          xl: 0,
          lg: 0,
          md: 0,
          sm: 0,
          xs: 24,
        }}
      >
        <Text
          className="gradient-service-text"
          fontSize={14}
          fontWeight={800}
          lineHeight="24px"
          letterSpacing="0.2em"
          mb={{
            xl: "md",
            _: "sm",
          }}
        >
          Service
        </Text>
        <Title1 width="100%" mb="xs">
          {title}
        </Title1>
        <Body2>{description}</Body2>
      </Box>
      <Box
        flex={{
          xl: 3,
          lg: 3,
          mg: 3,
          sm: 2.5,
          _: 1,
        }}
        pl={side === "left" ? commonImagePadding : 0}
        pr={side === "right" ? commonImagePadding : 0}
      >
        {children}
      </Box>
    </Flex>
  );
};

export const ServicesSection: React.FC = () => (
  <Container
    pb={{
      xl: 352,
      lg: 304,
      md: 240,
      _: 120,
    }}
    overflow="hidden"
  >
    <Display1
      width="100%"
      display="block"
      mb={{
        xl: 200,
        lg: 160,
        md: 120,
        _: 80,
      }}
    >
      What We Do
    </Display1>
    <ServiceItem
      side="left"
      title="Prototyping and Design"
      description="In our team, developers work side-by-side with designers, guided by design thinking, to ensure the best products with an exceptional user experience"
    >
      <DesignImage />
    </ServiceItem>
    <ServiceItem
      side="right"
      title="Web Application
      Development"
      description="Responsive websites, designed with the end user in mind. Build to meet the needs of the target audience"
    >
      <WebImage />
    </ServiceItem>
    <ServiceItem
      side="left"
      title="Mobile Application Development"
      description="Mobile apps built with extensive research, innovative designs, solid backend solutions, and a lot of testing are our way to go"
    >
      <MobilImage />
    </ServiceItem>
    <ServiceItem
      side="right"
      title="Backend Development"
      description="We build products using the latest tech solutions where systems scale to meet any requirement, operate quickly, integrate seamlessly with third-party services, and provide secure and reliable data storage"
    >
      <BackendImage />
    </ServiceItem>
    <ServiceItem
      side="left"
      title="Consultant"
      description="Our experts are available for consultation. Using their knowledge and expertise, they can help you overcome any technological obstacles"
    >
      <ConsultantImage />
    </ServiceItem>
  </Container>
);
