import React from "react";
import { Box } from "@cuneilego/web";
import { Body2, Display1 } from "../../ui";
import { useScrollAnimation } from "../../hoocks/useScrollAnimation";

export const Header: React.FC = () => {
  const containerId = "our-goal-header-id";
  const className = "our-goal-header";
  useScrollAnimation(className, containerId);

  return (
    <Box id={containerId}>
      <Box width="100%" overflow="hidden">
        <Display1 className={className} mb="md">
          Our Goal
        </Display1>
      </Box>
      <Box width={["100%", "100%", "66%"]} overflow="hidden">
        <Body2 className={className}>
          The goal of CuneiCode is to apply technology to all aspects of
          business, help structure businesses in a digital way, and find
          solutions for day-to-day problems while empowering businesses of all
          sizes and fostering their potential to be better prepared to face
          tomorrow’s challenges.
        </Body2>
      </Box>
    </Box>
  );
};
