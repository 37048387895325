import { useEffect, useRef, useState } from "react";
import { useScrollYPosition } from "./scrollYPosition";
import anime from "animejs";

export function useScrollAnimation(
  className: string,
  containerId: string,
  type: "vertical" | "horizontal" | "opacity" = "vertical"
) {
  const animationRef: any = useRef(null);
  const [isShowed, setIsShowed] = useState(false);
  const { scrollYPosition } = useScrollYPosition();

  useEffect(() => {
    anime.set(`.${className}`, {
      translateY: type === "vertical" ? "120%" : "0%",
      translateX: type === "horizontal" ? "-120%" : "0%",
      opacity: type === "opacity" ? 0 : 1,
    });

    animationRef.current = anime.timeline({
      targets: `.${className}`,
      loop: false,
      duration: type === "opacity" ? 2000 : 1000,
      easing: "easeInOutCubic",
      elasticity: 300,
      delay: (_, i) => i * 150,
    });
  }, []);

  useEffect(() => {
    const container = document.getElementById(containerId);
    if (!isShowed && container) {
      const offset = scrollYPosition + innerHeight - container.offsetTop;
      if (offset > 200) {
        animationRef.current.add({
          translateY: 0,
          translateX: 0,
          opacity: 1,
        });
        setIsShowed(true);
      }
    }
  }, [scrollYPosition]);
}
