import React from "react";
import { Box, Image } from "@cuneilego/web";
import designImage from "../../images/designShadow.png";
import designMockup from "../../images/designMockup.png";
import designDesign from "../../images/designDesign.png";
import { useImageScrollAnimation } from "../../hoocks/useImageScrollAnimation";

export const DesignImage: React.FC = () => {
  const containerId = "design-image-container";
  const className = "design-design";
  const { containerRef } = useImageScrollAnimation(className, containerId);

  return (
    <Box
      id={containerId}
      ref={containerRef}
      maxWidth={930}
      maxHeight={798}
      width="100%"
      style={{ aspectRatio: "1/0.85" }}
      overflow="hidden"
      borderRadius={16}
      position="relative"
      bg="#E0EEF3"
    >
      <Image
        className={className}
        position="absolute"
        top={0}
        left={0}
        maxWidth="100%"
        src={designImage}
        style={{ objectFit: "cover", aspectRatio: "1/0.85" }}
      />
      <Image
        className={className}
        position="absolute"
        top={0}
        left={0}
        maxWidth={"119%"}
        maxHeight={953}
        src={designMockup}
        style={{ objectFit: "cover", aspectRatio: "1/0.85" }}
      />
      <Image
        className={className}
        position="absolute"
        top={0}
        left={0}
        maxWidth="100%"
        src={designDesign}
        style={{ objectFit: "cover", aspectRatio: "1/0.85" }}
      />
    </Box>
  );
};
