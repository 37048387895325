import React from "react";
import { Box, themeBuilder, ThemeProvider } from "@cuneilego/web";
import {
  ContactSection,
  GoalSection,
  Header,
  HeroSection,
  MethodsSection,
  ServicesSection,
  SocialSection,
  TeamSection,
} from "./sections";

const breakpoints: any = ["375px", "744px", "950px", "1128px", "1440px"];
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

const lightColors = {
  rgba: {
    primary1: {
      10: "rgba(87, 71, 209, 0.05)",
    },
    success1: {
      10: "rgba(29, 139, 117, 0.05)",
    },
    secondary2: {
      10: "rgba(242, 105, 192, 0.05)",
    },
    danger1: {
      10: "rgba(231, 64, 64, 0.05)",
    },
    warning1: {
      10: "rgba(250, 153, 5, 0.05)",
    },
  },
  accent1: "#0A1931",
  accent2: "#E3E7ED",
  primary1: "#5747d1",
  secondary1: "#00b4d8",
  secondary2: "#f269c0",
  secondary3: "#0A1931",
  text1: "#202427",
  text2: "rgba(32, 36, 39, 0.65)",
  text3: "rgba(32, 36, 39, 0.48)",
  text4: "rgba(32, 36, 39, 0.3)",
  text5: "#FFFFFF",
  text6: "rgba(255, 255, 255, 0.65)",
  text7: "rgba(255, 255, 255, 0.48)",
  text8: "rgba(255, 255, 255, 0.3)",
  border1: "#CCD7E0",
  disabled1: "rgba(0, 0, 0, 0.12)",
  disabled2: "rgba(0, 0, 0, 0.26)",
  disabled3: "rgba(0, 0, 0, 0.38)",
  divider1: "rgb(227, 233, 237)",
  danger1: "#E74040",
  success1: "#1D8B75",
  elementBackground: "#ffffff",
  background1: "#ffffff",
  background2: "#F9F9F9",
  warning1: "#fa9905",
  info1: "#5463ff",
  outline: "#5463ff",
  gray6: "#CBCDCF",
  gray10: "#FAFDFF",
};

const themeName = "default";
themeBuilder.addTheme(themeName, lightColors);

const theme = themeBuilder.getThemeByName(themeName);

function App() {
  return (
    <ThemeProvider
      theme={{
        ...theme,
        space: {
          tiny: 4,
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
          xl: 40,
        },
        sizes: {
          tiny: 24,
          xs: 32,
          sm: 40,
          md: 48,
          lg: 56,
          xl: 64,
        },
        breakpoints,
        mediaQueries: {
          xs: `@media screen and (min-width: ${breakpoints[0]})`,
          sm: `@media screen and (min-width: ${breakpoints[1]})`,
          md: `@media screen and (min-width: ${breakpoints[2]})`,
          lg: `@media screen and (min-width: ${breakpoints[3]})`,
          xl: `@media screen and (min-width: ${breakpoints[4]})`,
        },
      }}
    >
      <Box width="100%">
        <HeroSection />
        <ServicesSection />
        <GoalSection />
        <MethodsSection />
        <TeamSection />
        <ContactSection />
      </Box>
    </ThemeProvider>
  );
}

export default App;
