import React from "react";
import { Box, Flex, Image } from "@cuneilego/web";
import { Body1, Container, Display1, Title2 } from "../../ui";
import methodSvg from "./methods.svg";
import userExperienceSvg from "./userExperience.svg";
import testSvg from "./test.svg";
import automationSvg from "./automation.svg";
import { useScrollAnimation } from "../../hoocks/useScrollAnimation";

const MethodItem = ({
  title,
  description,
  className,
}: {
  title: "User Experience" | "Testing" | "Automation";
  description: string;
  className: string;
}) => {
  function renderIcon() {
    switch (title) {
      case "User Experience":
        return <Image src={userExperienceSvg} size={48} />;
      case "Testing":
        return <Image src={testSvg} size={48} />;
      case "Automation":
        return <Image src={automationSvg} size={48} />;
    }
  }

  return (
    <Box mb={40}>
      <Box className={className}>
        {renderIcon()}
        <Title2 color="text5" mt="sm" mb="xs">
          {title}
        </Title2>
        <Body1 color="text5">{description}</Body1>
      </Box>
    </Box>
  );
};

export const MethodsSection: React.FC = () => {
  const titleContainerId = "method-title-container-id";
  const titleClassName = "method-title-container";
  useScrollAnimation(titleClassName, titleContainerId);

  const contentContainerId = "method-content-container-id";
  const contentClassName = "method-content-container";
  useScrollAnimation(contentClassName, contentContainerId, "horizontal");

  const imageContainerId = "method-image-container-id";
  const imageClassName = "method-image-container";
  useScrollAnimation(imageClassName, imageContainerId, "opacity");
  return (
    <Box size="100%" bg="secondary3">
      <Container
        py={{
          xl: 176,
          lg: 152,
          _: 120,
        }}
      >
        <Box
          id={titleContainerId}
          overflow="hidden"
          mb={{
            xl: 160,
            lg: 120,
            md: 120,
            sm: 120,
            xs: 48,
          }}
        >
          <Display1
            className={titleClassName}
            display={{
              xl: "inline-block",
              lg: "inline-block",
              md: "inline-block",
              _: "none",
            }}
            color="text5"
          >
            Our Methods Involve
          </Display1>
        </Box>
        <Flex
          flexDirection={{
            xl: "row",
            lg: "row",
            md: "row",
            sm: "column-reverse",
            xs: "column-reverse",
            _: "column-reverse",
          }}
          alignItems={{
            xl: "center",
            lg: "center",
            md: "flex-start",
            sm: "center",
            xs: "center",
            _: "center",
          }}
        >
          <Box flex={1} id={contentContainerId} overflow="hidden">
            <MethodItem
              className={contentClassName}
              title="User Experience"
              description="You are part of our team! We believe that the key to persistence is providing people with a clear and meaningful experience while they use our products or services. We research each case to ensure high efficiency throughout our products."
            />
            <MethodItem
              className={contentClassName}
              title="Testing"
              description="We are obsessed with quality. To make sure that the end result is a quality product, our applications are tested extensively. Our testing methods include AI, human and manual processes to ensure we deliver exceptional results."
            />
            <MethodItem
              className={contentClassName}
              title="Automation"
              description="Because why do the same thing over and over when we have robots to deal with it?"
            />
          </Box>
          <Box
            id={titleContainerId}
            overflow="hidden"
            display={{
              xl: "none",
              lg: "none",
              md: "none",
              sm: "inline-block",
              xs: "inline-block",
              _: "inline-block",
            }}
          >
            <Display1
              className={titleClassName}
              color="text5"
              my={{
                xs: 48,
              }}
            >
              Our Methods involve
            </Display1>
          </Box>
          <Box id={imageContainerId} flex={2}>
            <Image
              className={imageClassName}
              pl={{
                _: 0,
                xl: 64,
                lg: 64,
                md: 64,
                sm: 64,
              }}
              size="100%"
              src={methodSvg}
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
