import React, { memo } from "react";
import { Svg, SvgProps } from "@cuneilego/web";

const Github = ({ size = 24 }: SvgProps) => (
  <Svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    <g>
      <path d="M64 0H0v64h64V0Z" fill="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 5.792c-14.784 0-26.88 12.096-26.88 26.88a26.85 26.85 0 0 0 18.368 25.536c1.344.224 1.792-.672 1.792-1.344v-4.48c-7.392 1.568-8.96-3.584-8.96-3.584-1.12-3.136-2.912-4.032-2.912-4.032-2.688-1.568 0-1.568 0-1.568 2.688.224 4.032 2.688 4.032 2.688 2.464 4.032 6.272 2.912 7.84 2.24.224-1.792.896-2.912 1.792-3.584-6.048-.672-12.32-2.912-12.32-13.216a10.82 10.82 0 0 1 2.688-7.168c0-.896-1.12-3.584.448-7.168 0 0 2.24-.672 7.392 2.688 2.24-.672 4.48-.896 6.72-.896 2.24 0 4.48.224 6.72.896 5.152-3.584 7.392-2.688 7.392-2.688 1.568 3.808.448 6.496.224 7.168 1.792 1.792 2.688 4.256 2.688 7.168 0 10.304-6.272 12.544-12.32 13.216.896.896 1.792 2.464 1.792 4.928v7.392c0 .672.448 1.568 1.792 1.344a26.85 26.85 0 0 0 18.368-25.536C58.88 17.888 46.784 5.792 32 5.792Z"
        fill="#7C858F"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="none" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </Svg>
);

const LinkedIn = ({ size = 24 }: SvgProps) => (
  <Svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    <g>
      <path d="M64 0H0v64h64V0Z" fill="none" />
      <path
        d="M56.64 5.12H7.36c-1.344 0-2.24.896-2.24 2.24v49.28c0 1.344.896 2.24 2.24 2.24h49.28c1.344 0 2.24-.896 2.24-2.24V7.36c0-1.344-.896-2.24-2.24-2.24ZM21.024 51.04h-7.84V25.28h8.064v25.76h-.224Zm-4.032-29.344c-2.464 0-4.704-2.016-4.704-4.704 0-2.464 2.016-4.704 4.704-4.704 2.464 0 4.704 2.016 4.704 4.704 0 2.688-2.016 4.704-4.704 4.704ZM51.04 51.04h-8.064V38.496c0-2.912 0-6.72-4.032-6.72-4.256 0-4.704 3.136-4.704 6.496V51.04h-8.064V25.28h7.616v3.584c1.12-2.016 3.584-4.032 7.616-4.032 8.064 0 9.632 5.376 9.632 12.32V51.04Z"
        fill="#7C858F"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </Svg>
);

const Twitter = ({ size = 24 }: SvgProps) => (
  <Svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    <g>
      <path d="M64 0H0v64h64V0Z" fill="none" />
      <path
        d="M58.88 15.425c-2.016.895-4.032 1.567-6.272 1.791 2.24-1.344 4.032-3.584 4.928-6.048-2.24 1.345-4.48 2.24-6.944 2.689a10.844 10.844 0 0 0-8.064-3.584c-6.048 0-10.976 4.928-10.976 10.976 0 .896 0 1.792.224 2.463-9.408-.447-17.472-4.927-22.848-11.647-1.12 1.792-1.568 3.584-1.568 5.6 0 3.808 2.016 7.168 4.928 9.184-1.792 0-3.584-.448-4.928-1.345v.224c0 5.377 3.808 9.856 8.736 10.752-.896.225-1.792.449-2.912.449-.672 0-1.344 0-2.016-.224 1.344 4.48 5.376 7.616 10.304 7.616-3.808 2.912-8.512 4.704-13.664 4.704-.896 0-1.792 0-2.688-.224 4.928 3.136 10.752 4.927 16.8 4.927 20.384 0 31.36-16.8 31.36-31.36v-1.343c2.24-1.569 4.032-3.584 5.6-5.6Z"
        fill="#7C858F"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </Svg>
);

const Facebook = ({ size = 24 }: SvgProps) => (
  <Svg
    width={size}
    height={size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    <g>
      <path d="M64 0H0v64h64V0Z" fill="none" />
      <path
        d="m25.35 56.64-.07-22.4h-8.96v-8.96h8.96v-5.6c0-8.313 5.148-12.32 12.564-12.32 3.553 0 6.606.265 7.496.383v8.688l-5.144.002c-4.033 0-4.814 1.917-4.814 4.73v4.117H47.12l-4.48 8.96h-7.258v22.4H25.35Z"
        fill="#7C858F"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h64v64H0z" />
      </clipPath>
    </defs>
  </Svg>
);

export const GithubIcon = memo(Github);
export const LinkedInIcon = memo(LinkedIn);
export const TwitterIcon = memo(Twitter);
export const FacebookIcon = memo(Facebook);
